<template>
  <section :class="$style.users">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['firstName', 'lastName']"
          @querySearch="querySearch"
          @selectItem="handleSelectUser"
          @handleFindItems="handleFindUsers"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-button type="primary" @click="$router.push('users/create')">
        Создать пользователя
      </el-button>
    </div>
    <el-table :data="users" stripe>
      <el-table-column prop="firstName" label="Имя пользователя">
      </el-table-column>
      <el-table-column prop="lastName" label="Фамилия"> </el-table-column>
      <el-table-column prop="phone" label="Номер"> </el-table-column>
      <el-table-column prop="email" label="Email"> </el-table-column>
      <el-table-column prop="birthday" label="Дата рождения">
        <template slot-scope="scope">
          {{ scope.row.birthday ? formatDate(scope.row.birthday) : '' }}
        </template>
      </el-table-column>
      <el-table-column label="Последний вход на сайт">
        <template slot-scope="scope">
          {{
            scope.row.lastSignedInAt ? formatDate(scope.row.lastSignedInAt) : ''
          }}
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="'users/' + String(scope.row.id)"
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import { formatDateFullYear } from '@/helpers'
import addQueryParamsAddwine from '@/mixins/addQueryParamsAddwine'
export default {
  mixins: [addQueryParamsAddwine(function(params) { this.getList(params); })],
  components: {
    ActionButtons,
    Autocomplete,
  },
  data() {
    return {
      users: [],
      limit: 20,
      page: 1,
      total: 10,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    initializeFromQuery() {
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.search = this.$route.query.search || ''
      this.users = this.$route.query.users || []
      this.limit = this.$route.query.limit || 20
    },
    formatDate(date) {
      return formatDateFullYear(date)
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        ...this.filters,
      }

      if (this.search) {
        query.search = this.search
      }
      const { value, error } = await delivery.AccountsCore.UsersActions.getList(
        query,
      )
      this.updatedQueryParams(query);
      if (error) {
        loading.close()
        return
      }

      this.users = value.data
      this.total = value.meta.count
      loading.close()
    },

    async handleFindUsers() {
      await this.getList()
    },
    handleSelectUser(selectedUser) {
      this.$router.push('/addwine/users/' + selectedUser.id)
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } = await delivery.AccountsCore.UsersActions.getList(
        {
          limit: this.limit,
          page: this.page,
          search: queryString,
        },
      )

      if (error) return
      setSearchItems(value.data)
    },
    async remove(data) {
      const isConfirm = confirm(
        `Вы точно хотите удалить ${data.firstName} ${data.lastName} ?`,
      )
      if (isConfirm) {
        const res = await delivery.AccountsCore.UsersActions.delete(data.id)
        if (!res.error) {
          this.getList()
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.users {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .button {
      margin-bottom: 1rem;
      margin-left: 0;
      margin-bottom: 0;
      border-radius: 0;
    }
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
